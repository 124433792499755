<template>
  <v-dialog v-model="permitHistoryDialog" persistent max-width="800">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $t("card.permit-answer-history") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="permitHistoryDialog = false"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon>{{ iconClose }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.close-not-save") }}</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4 d-inline-flex justify-center flex-column">
        <p>{{ $t("text.permit-answer-detail") }}</p>
        <div class="table-responsive-container">
          <table border="1" class="table__permit-history table-responsive">
            <tr>
              <th width="32%" class="py-2">{{ $t("text.received-by") }}</th>
              <th width="15%" class="py-2">{{ $t("text.date") }}</th>
              <th width="47%" class="py-2">
                {{ $t("text.approval-and-comment") }}
              </th>
            </tr>
            <tr v-for="item in singleMemoRecipients" :key="item.nik">
              <td class="py-1">{{ item.nama }}<br />({{ item.nama_jabatan }})</td>
              <td class="py-1" v-if="item.comment">
                {{ $date(item.reply_at).format("DD MMMM YYYY HH:mm") + " WIB" }}
              </td>
              <td class="py-1" v-if="item.comment">
                <strong class="text-uppercase">{{getApproval(item.is_approved)}}&nbsp;</strong>
                 {{$t('text.with-comment') +' '+ item.comment}}
              </td>
              <td class="py-1" colspan="2" v-if="!item.comment">
                {{ item.izin_prinsip_status }}
              </td>
            </tr>
          </table>
        </div>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="red darken-1"
          @click="permitHistoryDialog = false"
        >
          <v-icon class="mr-2">{{ iconClose }}</v-icon>
          {{ $t("button.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiClose } from "@mdi/js";
export default {
  name: "permit-answer-history-modal",
  props: {
    singleMemo: { type: [Object, Array] },
  },
  data: () => ({
    iconClose: mdiClose,
  }),
  computed: {
    singleMemoRecipients() {
      if (!this.singleMemo || !this.singleMemo.penerima) return [];
      return this.singleMemo.penerima;
    },
    permitHistoryDialog: {
      get() {
        return this.$store.state.inbox.permitHistoryDialog;
      },
      set(val) {
        this.$store.commit("inbox/SET_PERMIT_HISTORY_DIALOG", val);
      },
    },
  },
  methods: {
    getApproval(val) {
      if (!val) return "";
      let intVal = parseInt(val);
      let result = this.$t("text.reject");
      switch (intVal) {
        case 0:
          result = this.$t("text.reject");
          break;
        case 1:
          result = this.$t("text.agree");
          break;
        case 2:
          result = this.$t("text.review");
          break;
        default:
          result = this.$t("text.reject");
          break;
      }
     return result;
  },
  }
};
</script>